import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

setTimeout(() => {
    const loaderContainer = document.getElementById("loader-container");
    if (loaderContainer != null) {
        loaderContainer.style.display = "none";
    }
    const appContainer = document.getElementById("app");
    if (appContainer != null) {
        appContainer.style.display = "block";
    }
    createApp(App).use(store).use(router).mount('#app');
}, 1000)