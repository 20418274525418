<template>
  <div class="main-container">
    <div class="hamburger-container">
      <button
        class="hamburger hamburger--collapse"
        :class="{ 'is-active': menuVisible }"
        @click="toggleMenu()"
        type="button"
      >
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
    <aside class="toc" :class="{ 'toc-visible': menuVisible }">
      <nav>
        <ol>
          <li><a href="#" @click="goTo(1)">CV</a></li>
          <li>
            <a href="#" @click="goTo(3)">Bureau</a>
            <ol>
                <li><a href="#" @click="goTo(4)">2016 - La manufacture</a></li>
                <li><a href="#" @click="goTo(7)">2020 - Ilot capucines</a></li>
            </ol>
          </li>
          <li>
            <a href="#" @click="goTo(8)">Equipements</a>
            <ol>
                <li><a href="#" @click="goTo(10)">2011 - Ecole de musique</a></li>
                <li><a href="#" @click="goTo(11)">2020 - MA Extension</a></li>
                <li><a href="#" @click="goTo(13)">2018 - MA ISD</a></li>
                <li><a href="#" @click="goTo(15)">2019 - Huawei</a></li>
                <li><a href="#" @click="goTo(17)">2020 - Aéroport Lyon T2</a></li>
                <li><a href="#" @click="goTo(19)">2020 - Le lieu unique</a></li>
                <li><a href="#" @click="goTo(21)">2016 - EPHAD</a></li>
            </ol>
          </li>
          <li>
            <a href="#" @click="goTo(23)">Hôtel</a>
            <ol>
                <li><a href="#" @click="goTo(24)">2020 - Novotel GDL</a></li>
                <li><a href="#" @click="goTo(25)">2020 - Hilton Canopy</a></li>
                <li><a href="#" @click="goTo(27)">2023-2024 - Villa Saint Germain</a></li>
                <li><a href="#" @click="goTo(28)">2018 - Le Barn Hotel</a></li>
                <li><a href="#" @click="goTo(31)">2021-2024 - Chateau de Bains</a></li>
                <li><a href="#" @click="goTo(35)">2021-2024 - Ferme du Chesnay</a></li>
                <li><a href="#" @click="goTo(39)">2022 - Rocabella</a></li>
                <li><a href="#" @click="goTo(40)">2021-2024 - Chateau de la forêt</a></li>
            </ol>
          </li>
          <li>
            <a href="#" @click="goTo(43)">Logements</a>
            <ol>
                <li><a href="#" @click="goTo(44)">2021 - Ateliers d'artistes</a></li>
                <li><a href="#" @click="goTo(45)">2022-2023 - Exploitation</a></li>
                <li><a href="#" @click="goTo(47)">2021-2022 - Charenton</a></li>
                <li><a href="#" @click="goTo(48)">2012 - Surélévation</a></li>
                <li><a href="#" @click="goTo(49)">2013 - Hors-zone</a></li>
                <li><a href="#" @click="goTo(50)">2013 - Cellul'ose</a></li>
            </ol>
          </li>
        </ol>
      </nav>
    </aside>
    <div class="container">
      <div ref="book" class="book-container flip-book">
        <div class="page">
          <img src="../assets/book-images/0003.jpg" />
        </div>
        <div v-if="!isPortait" class="page">
          <img src="../assets/book-images/0003-filler.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0004.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0005.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0006.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0007.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0008.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0009.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0010.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0011.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0012.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0013.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0014.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0015.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0016.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0017.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0018.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0019.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0020.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0021.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0022.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0023.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0024.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0025.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0026.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0027.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0028.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0029.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0030.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0031.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0032.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0033.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0034.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0035.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0036.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0037.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0038.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0039.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0040.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0041.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0042.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0043.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0044.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0045.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0046.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0047.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0048.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0049.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0050.jpg" />
        </div>
        <div class="page">
          <img src="../assets/book-images/0051.jpg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";
import { PageFlip } from "page-flip";

export default class Book extends Vue {
  pageFlip: any;

  bookRef: any;

  isPortait = false;

  menuVisible = false;

  mounted() {
    const htmlParentElement = this.$refs["book"];
    this.init(htmlParentElement, false);
    
    window.addEventListener(
      "orientationchange",
      (event: any) => {
        if (
          event.target != null &&
          event.target.screen != null &&
          event.target.screen.orientation != null
        ) {
          const orientation = event.target.screen.orientation.type;
          this.$nextTick(() => {
            setTimeout(() => {
              if (orientation.startsWith("portrait")) {
                this.pageFlip.updateOrientation("portrait");
                this.reset({
                  data: {
                    mode: "portrait",
                  },
                });
              } else {
                this.pageFlip.updateOrientation("landscape");
                this.reset({
                  data: {
                    mode: "landscape",
                  },
                });
              }
            }, 10);
          });
        }
      },
      false
    );
  }

  init(htmlParentElement: any, destroy: boolean) {
    if (destroy) {
      this.pageFlip.getUI().destroy();
    }
    const settings = {
      width: 800,
      height: 1130,
      size: "stretch",

      minWidth: 315,
      maxWidth: 1000,
      minHeight: 420,
      maxHeight: 1350,

      maxShadowOpacity: 0.5, // Half shadow intensity
      // mobileScrollSupport: false,
      // showCover: true
    };

    this.pageFlip = new PageFlip(htmlParentElement, settings);
    this.pageFlip.on("init", this.reset);
    // this.pageFlip.on("changeOrientation", this.reset);
    this.pageFlip.loadFromHTML(document.querySelectorAll(".page"));
  }

  reset(e: any) {
    this.isPortait = e.data.mode == "portrait";
    this.$nextTick(() => {
      this.pageFlip.updateFromHtml(document.querySelectorAll(".page"));
    });
  }
  toggleMenu() {
    this.menuVisible = !this.menuVisible;
  }

  goTo(pageNumber: number) {
    pageNumber--;
    if (pageNumber > 0) {
      if (this.isPortait) {
        pageNumber = pageNumber - 1;
      }
    }
    this.pageFlip.flip(pageNumber);
    this.menuVisible = false;
  }
}
</script>

<style scoped>
.container {
  margin-top: 50px;
}

img {
  width: 100%;
}

.book-container {
  overflow: hidden;
}

.toc {
  position: fixed;
  z-index: 9998;
  background-color: #88a4ba;
  height: 0;
  min-width: 16%;
  overflow-x: hidden;
  overflow-y: scroll;
  transition: height 0.3s linear;
}

.toc.toc-visible {
  height: 100%;
}

.toc ol {
  margin: auto;
  padding: 20px;
}
.toc ol li a {
  white-space: nowrap;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
}

.toc ol li a:hover {
  text-decoration: underline;
}

ol > li {
  list-style-type: none;
  margin-bottom: 10px;
}

.hamburger-container {
  position: fixed;
  z-index: 9999;
  -webkit-user-select: none;
  user-select: none;
  height: 50px;
  width: 100%;
  padding-left: 10px;
  background-color: #88a4ba;
  top: 0;
}
button {
  height: 52px;
  margin-top: -2px;
}
</style>