<template>
  <div>
    <section>
      <router-view />
    </section>
  </div>
  
</template>

<style lang="scss">
</style>
