
import { Vue } from "vue-class-component";
import { PageFlip } from "page-flip";

export default class Book extends Vue {
  pageFlip: any;

  bookRef: any;

  isPortait = false;

  menuVisible = false;

  mounted() {
    const htmlParentElement = this.$refs["book"];
    this.init(htmlParentElement, false);
    
    window.addEventListener(
      "orientationchange",
      (event: any) => {
        if (
          event.target != null &&
          event.target.screen != null &&
          event.target.screen.orientation != null
        ) {
          const orientation = event.target.screen.orientation.type;
          this.$nextTick(() => {
            setTimeout(() => {
              if (orientation.startsWith("portrait")) {
                this.pageFlip.updateOrientation("portrait");
                this.reset({
                  data: {
                    mode: "portrait",
                  },
                });
              } else {
                this.pageFlip.updateOrientation("landscape");
                this.reset({
                  data: {
                    mode: "landscape",
                  },
                });
              }
            }, 10);
          });
        }
      },
      false
    );
  }

  init(htmlParentElement: any, destroy: boolean) {
    if (destroy) {
      this.pageFlip.getUI().destroy();
    }
    const settings = {
      width: 800,
      height: 1130,
      size: "stretch",

      minWidth: 315,
      maxWidth: 1000,
      minHeight: 420,
      maxHeight: 1350,

      maxShadowOpacity: 0.5, // Half shadow intensity
      // mobileScrollSupport: false,
      // showCover: true
    };

    this.pageFlip = new PageFlip(htmlParentElement, settings);
    this.pageFlip.on("init", this.reset);
    // this.pageFlip.on("changeOrientation", this.reset);
    this.pageFlip.loadFromHTML(document.querySelectorAll(".page"));
  }

  reset(e: any) {
    this.isPortait = e.data.mode == "portrait";
    this.$nextTick(() => {
      this.pageFlip.updateFromHtml(document.querySelectorAll(".page"));
    });
  }
  toggleMenu() {
    this.menuVisible = !this.menuVisible;
  }

  goTo(pageNumber: number) {
    pageNumber--;
    if (pageNumber > 0) {
      if (this.isPortait) {
        pageNumber = pageNumber - 1;
      }
    }
    this.pageFlip.flip(pageNumber);
    this.menuVisible = false;
  }
}
